<template>
    <div class="edu-frame">
        <edu-nav-bar :title="active==0?'首页':'我的'" :show="show" :hideFunc="true" :hideBack="true" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <index-page v-if="active == 0"></index-page>
        <my-page v-if="active == 1"></my-page>
        <van-tabbar v-model="active" @change="changeTab" :style="spstyle">
            <van-tabbar-item icon="wap-home" :style="{'background-color': $store.state.theme}">
                <div :style="{color: active==0? ($store.state.theme == 'white' ? 'dodgerblue' : 'white'): '#646566'}">主页</div>
                <template #icon>
                    <van-icon :color="active==0? ($store.state.theme == 'white' ? 'dodgerblue' : 'white'): '#646566'" name="wap-home" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item icon="manager" :style="{'background-color': $store.state.theme}">
                <div :style="{color: active==1? ($store.state.theme  == 'white' ? 'dodgerblue' : 'white'): '#646566'}">我的</div>
                <template #icon>
                    <van-icon :color="active==1? ($store.state.theme  == 'white' ? 'dodgerblue' : 'white'): '#646566'" name="manager" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
    import {Tabbar, TabbarItem,Icon} from 'vant';
    import IndexPage from "@/Main/Index";
    import MyPage from '@/Main/My';
    import EduNavBar from "@/components/EduNavBar";
    import EduConstants from "@/api/EduConstants";
    export default {
        components: {
            EduNavBar,
            VanTabbar: Tabbar,
            VanTabbarItem: TabbarItem,
            IndexPage,
            MyPage,
            VanIcon: Icon
        },
        data() {
            return {
                show: false,
                active: this.$store.state.curTab == null ? 0 :this.$store.state.curTab,
                spstyle: {}
            }
        },
        methods: {
            changeTab(tab) {
                // console.log('stateTab == ' + this.$store.state.curTab)
                this.$store.dispatch('changeTab', tab)
            }
        },
        mounted() {
            if (EduConstants.isMobile()) {
                this.spstyle = ''
            } else {
                this.spstyle = 'width: 500px;left: auto;'
            }
            // var cust = Tools.getCurCust();
            // console.log('cust', cust)
            if (sessionStorage.getItem('isreuploadavatar') != null && sessionStorage.getItem('isreuploadavatar') == 1) {
              this.$router.push({ name: 'sczp' })
            }
        }
    }
</script>
<style scoped>
</style>
